/**
 * @param {string} dateString
 */
const checkDateFormat = (dateString) => {
    if (dateString === '' || dateString === undefined) {
        return true;
    }

    const regex = /^[0-9]{4}.(0[1-9]|1[0-2]).(0[1-9]|[1-2][0-9]|3[0-1])$/;
    return regex.test(dateString);
}

/**
 * @param {string} dateOfBirth
 */
const checkCustomerAge = (dateOfBirth) => {
    if (dateOfBirth === '') {
        return true;
    }

    const MINIMUM_AGE = 16;
    const birthDate = new Date(dateOfBirth);
    const currentDate = new Date();
    const yearsDiff = currentDate.getFullYear() - birthDate.getFullYear();

    if (yearsDiff < MINIMUM_AGE) {
        return false;
    } else if (yearsDiff === MINIMUM_AGE) {
        const currentMonth = currentDate.getMonth();
        const birthMonth = birthDate.getMonth();

        if (currentMonth < birthMonth) {
            return false;
        } else if (currentMonth === birthMonth) {
            const currentDay = currentDate.getDate();
            const birthDay = birthDate.getDate();

            if (currentDay < birthDay) {
                return false;
            }
        }
    }

    return true;
}

export {
    checkCustomerAge,
    checkDateFormat
};
