// Define reusable config objects auth form fields
import * as yup from 'yup';
import { storeToRefs } from 'pinia';
import { useMessageStore } from '~/stores/messages';

export default () => {
    // Fetch messages
    const messagesStore = useMessageStore();
    const { messages: staticKeys } = storeToRefs(messagesStore);

    const _firstNameConfig = {
        label: staticKeys.value?.account_form_firstname_label,
        fieldType: 'text',
        name: 'firstName',
        required: 'required',
        validationRules: yup.string()
            .required(staticKeys.value?.account_form_firstname_error_message)

    }

    const _lastNameConfig = {
        label: staticKeys.value?.account_form_lastname_label,
        fieldType: 'text',
        name: 'lastName'
    }

    const _birthdayConfig = {
        label: staticKeys.value?.account_form_birthday_label,
        fieldType: 'date',
        name: 'birthDate',
        validationRules: yup.string()
            .test(
                'validateBirthDate',
                staticKeys.value?.account_form_birthday_error_message,
                value => checkCustomerAge(value)
            )
            .test(
                'validDateFormat',
                staticKeys.value?.general_date_validation_error_message,
                value => checkDateFormat(value)
            )
    }

    const _postalCodeConfig = {
        label: staticKeys.value?.account_form_postalcode_label,
        placeholder: staticKeys.value?.account_form_postalcode_placeholder,
        fieldType: 'text',
        name: 'postalCode',
        validationRules:
            yup.string()
                .matches(/^\d{5}$/, {
                    excludeEmptyString: true,
                    message: staticKeys.value?.account_form_postalcode_error_message
                })
    }

    const _genderConfig = {
        female: {
            fieldType: 'radio',
            label: staticKeys.value?.account_form_gender_female_label,
            name: 'salutationCode',
            id: 'female',
            value: 'female'
        },
        male: {
            fieldType: 'radio',
            label: staticKeys.value?.account_form_gender_male_label,
            name: 'salutationCode',
            value: 'male',
            id: 'male'
        },
        diverse: {
            fieldType: 'radio',
            label: staticKeys.value?.account_form_gender_divers_label,
            name: 'salutationCode',
            value: 'diverse',
            id: 'diverse'
        }
    };

    const _emailConfig = {
        label: staticKeys.value?.account_form_email_label,
        placeholder: staticKeys.value?.account_form_email_placeholder,
        fieldType: 'email',
        required: 'required',
        name: 'email'
    };

    const _passwordConfig = {
        label: staticKeys.value?.account_form_password_label,
        fieldType: 'password',
        name: 'password',
        includeToggle: true
    };

    /**
     * Generates an array of user preference objects based on the input userPreferences array.
     *
     * @param {Array} userPreferences - The array of user preferences.
     * @return {Array} An array of user preference objects.
     */
    const getUserPreferences = (userPreferences) => {
        return userPreferences.map((obj) => {
            return {
                fieldType: 'checkbox',
                value: obj.id,
                smart: true,
                id: `preferences-[${obj.id}]`,
                label: obj.name,
                name: 'preferences'
            }
        })
    }

    return {
        _firstNameConfig,
        _lastNameConfig,
        _birthdayConfig,
        _postalCodeConfig,
        _passwordConfig,
        _genderConfig,
        _emailConfig,
        getUserPreferences
    }
}
