<script setup>
import Error from 'assets/icons/error.svg';
import Success from 'assets/icons/success.svg';

const props = defineProps({
    msgConfig: {
        type: Object,
        default: () => {
        }
    }
});

const formInputClass = computed(() => {
    return {
        'form-error-message': props?.msgConfig?.error,
        'form-success-message': props?.msgConfig?.success
    }
});

const message = computed(() => props?.msgConfig?.success ? props?.msgConfig?.successMessage : props?.msgConfig?.errorMessage)
</script>

<template>
    <span v-if="msgConfig.error || msgConfig.success" :class="formInputClass">
        <Error v-if="msgConfig.error" />
        <Success v-else /> {{ message }}
    </span>
</template>

<style lang="scss">
.form-error-message {
    @include form-input-message($type: 'error');
}

.form-success-message {
    @include form-input-message($type: 'success');
}
</style>
